$base:#1C1B1B;
$base-lite:#EFEBE4;
$blue:#00A1E9;
$pink:#d94667;
$txt:#040000;
$gray-lite1:#efefef;
$gray-lite2:#f7f8f8;
$line:#BDBAB9;
$gray:#595757;
$gray2:#c9caca;
$red:#9D1014;
$yellow:#FFE100;


$ease1: cubic-bezier(0, 1.64, 0.69, 0.89);
$line:1px solid #C0C0C0;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

@mixin OPS{
	font-family: 'Open Sans', sans-serif;
}

@mixin flex-centering{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

@mixin fitW($w:$m){
	margin-left: -$w !important;
	width: 100vw !important;
}


@mixin pfix($gap){
	$em:$gap/2;
	margin-top: -$em;
	margin-bottom: -$em;
}

@mixin note($pl:1.4em){
	padding-left: $pl;
	position: relative;
	i,&:before{
		font-style: normal;
		position: absolute;
		top: 0;left: 0;
	}
}

@mixin tap($scale:1.2){
	transition: 200ms $ease1;
	transition-property: transform;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	a{
		-webkit-tap-highlight-color:rgba(0,0,0,0);
	}
	&:active{
		@include transform(scale($scale));
		will-change:transform;
	}
}


@mixin textshadow($color:#fff){
	text-shadow:0px 2px 1px $color, 0px 1px 1px $color, 1px 1px 1px $color, 1px 0px 1px $color, 2px 0px 1px $color, 1px -1px 1px $color, 1px -2px 1px $color,0px -2px 1px $color, -1px -2px 1px $color, -2px -2px 1px $color, -2px -1px 1px $color, -2px 0px 1px $color, -2px 1px 1px $color, -1px 1px 1px $color
}


@mixin grade1
{
  background : -moz-linear-gradient(50% 100% 90deg,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 1) 100%);
  background : -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background : -webkit-gradient(linear,50% 100% ,50% 0% ,color-stop(0,rgba(255, 255, 255, 0) ),color-stop(1,rgba(255, 255, 255, 1) ));
  background : -o-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background : -ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#FFFFFF' ,GradientType=0)";
  background : linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#FFFFFF' , GradientType=0);
}



@mixin minchou{
	//font-family: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
	font-family: "游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
}

@mixin gothic{
	font-family:"Hiragino Sans","YuGothic","Hiragino Kaku Gothic ProN",Meiryo,"メイリオ",sans-serif !important;
}


@mixin bg-img($url,$size:100% 100%,$position:center center,$repeat:no-repeat){
	background-image: $url;
	background-repeat: $repeat;
	-webkit-background-size:$size ;
	background-size:$size ;
	background-position:$position;
}



@mixin transition($arg){
	-webkit-transition:$arg;
	-moz-transition:$arg;
	-ms-transition:$arg;
	-o-transition:$arg;
	//filter:$arg;
}


@mixin filter($arg){
	-webkit-filter:$arg;
	-moz-filter:$arg;
	-ms-filter:$arg;
	-o-filter:$arg;
	filter:$arg;
}

@mixin transform($arg){
	-webkit-transform:$arg;
	-moz-transform:$arg;
	-ms-transform:$arg;
	-o-transform:$arg;
	transform:$arg;
}

@mixin brend($color){
	background-blend-mode: exclusion;
	background-color: $color ;
}

@mixin webfont($fw:600){
		//font-family: 'Ropa Sans', sans-serif;
		font-family: 'Roboto Slab', serif !important;
		font-weight: $fw;
	}

@mixin header-icon-shadow(){
	-webkit-filter:drop-shadow(rgba(0,0,0,0.8) 1px 1px 2px);
}

@mixin a-cover{
	position: absolute;
	display: block;
	z-index: 1;
	top: 0;
	left: 0;
	width:100%;
	height:100%;
}

@mixin centering{
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform(translate(-50%, -50%));
}


//!! サイドにカッコ
@mixin side-kakko($W:15px,$Bw:1px,$C:#666){
	position: relative;
	&:before{
		content: "";
		position: absolute;
		top: 0;left: 0;
		border: $Bw solid $C;
		border-right-width: 0;
		height: 100%;
		width: $W;
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;right: 0;
		border: $Bw solid $C;
		border-left-width: 0;
		height: 100%;
		width: $W;
	}
}

@mixin bar($color){
	content: "";
	width: 50px;
	height: 5px;
	background:$color ;
	display:block;
	margin: 0 auto;
}


@mixin anchor($color:#000,$size:8px,$Bw:1px,$left:auto,$right:10px){
	display: block;
	position: absolute;
	top: 50%;left:$left;right:$right;
	content: "";
	width: $size;height: $size;
	border: 1px solid  $color;
	border-width: 0 $Bw $Bw 0;
	@include transform(rotate(-45deg) translateY(-50%));
	transform-origin: center center;
}

/* !!------------------------------------ */
/* !! クリップ */
@mixin clip-path($d){
	clip-path: $d;
}
