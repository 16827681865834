@charset "UTF-8";
/* CSS Document */

$px:0.073vw;

* , :before , :after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}


body.showBlk *,
body.showBlk *:after,
body.showBlk *:before {
	background-color: rgba(48, 210, 222, .13);
}


a {
	color:inherit;
	text-decoration: none;
	transition: all 0.3s linear;
}

ul  {
	list-style: none;
}

h1 , h2 , h3 , h4 , ul , ol , dl , dd , p , hr , figure {
	margin: 0;
	padding: 0;
}

header,nav,main,footer,section,aside{
	display: block;
}


form {
	margin-bottom: 0;
}

img {
	vertical-align:bottom;
}

::selection {
	color: #fff;
	background: #2C3F89;
}

::-moz-selection {
	color: #2C3F89;
	//background: #00aebb;
}

input::selection,
textarea::selection {
	color: #221814;
	background: #dbdcdc;
}

input[type=text],
input[type=tel],
input[type=email],
textarea,
button{
	-webkit-appearance: none;
	appearance: none;
	border: none;
	 
}

p{
	line-height: 1.87;
	//@include gothic;
	font-size: 1.5rem;
	text-align: justify;
	//@include minchou;
}

.ar{
	text-align: right;
}
.ac{
	text-align: center;
}

i{
	font-style: normal;
}

.fr{
	float: right;
}


.sp{
	display: none;
}

.idx{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
}


.nh{
	opacity: 0;
	pointer-events: none;
}

/* !!------------------------------------ */
/* !! 共通css */

html{
	font-size: 62.5%;
	margin: 0;
}

body{
	margin: 0;
	//overflow-x: hidden;
	@include gothic;
	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	//max-width: 1920px;
	background: #fff;
	color: $txt;
	font-size: 1.5rem;
}


main{
	display: block;
	min-width: 1100px;
	padding-bottom: 0px;
}

main > section {
	padding: 50px 0;
	padding-bottom: 0;
	.inner{
		max-width: 1000px;
		margin: 0 auto;
		position: relative;
	}

}


//!! - 見出し
h1.ttl,
h2.sec{
	text-align: left;
	font-weight: normal;
	font-size: 1.6rem;
	line-height: 1;
	letter-spacing: .1em;
	i{
		@include OPS;
		font-weight: bold;
		font-size:3rem;
		display: block;
		margin-bottom: 15px;
	}
}

h1.ttl.sim{
	text-align: center;
}


h3.hd{
	text-align: left;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 1;
	letter-spacing: .1em;
	i{
		@include OPS;
		font-weight: bold;
		font-size:2.6rem;
		display: block;
		margin-top: 10px;
	}
}



//!! - btn
.btn{
	width: 200px;
	display: block;
	line-height: 40px;
	height: 40px;
	background: #000;
	text-align: center;
	color: #fff;
	@include OPS;
	letter-spacing: .1em;
	font-weight: bold;
	position: relative;
	z-index: 1;
	&.l{
		width: 300px;
		height: 60px;
		line-height: 60px;
		
	}
	&.anchor{
		position: relative;
		&:after{
			@include anchor(#fff,$right:20px);//$color,$size,$Bw,$left,$right
			border-width: 0 0 1px 1px;
			margin-top: -3px;
		}
	}
}

.btn-toggle{
	width: 250px;
	height: 40px;
	line-height: 40px;
	background: #000;
	position: relative;
	border-radius: 20px;
	font-size: 14px;
	color: #fff;
	text-align: center;
	letter-spacing: .1em;
	font-weight: bold;
	cursor: pointer;
	display: block;
	&:after{
		content: "詳しく見る";
	}
	&.opened{
		&:after{
			content: "閉じる" !important;
		}
		&:before{
			content:"-";
		}
	}
	&:before{
		content:"＋";
		position: absolute;
		line-height: 1;
		right: 20px;
		top: 50%;
		@include transform(translate(50%,-50%));		
	}
}

.btn-modal{
	@extend .btn-toggle;
	&:after{
		content: none;
							
	}
	width: 500px;font-size: 14px;
	letter-spacing: .05em;
}

.btn.back{
	color: $txt;
	background: $gray2;
	width: 300px;
	height: 60px;
	line-height: 60px;
	&:after{
		content: "";
		width: 100px;
		height: 7px;
		position: absolute;
		left: 0;
		top: 50%;
		@include transform(translate(-50%,-50%) rotate(180deg));
		@include bg-img(url(../img/sign2@2x.png),auto 100%,right center); //$url,$size,$position,$repeat					
	}
}


a.na{
	opacity: .2;
	pointer-events: none;

}

a.call{
	line-height: 60px;
	&:before{
		content:"";
		width: 25px;
		height: 25px;
		display: inline-block;
		margin-right: 5px;
		line-height: 60px;
		@include bg-img(url(../img/icon_tell@2x.png));
		
	}
	@include OPS;
	font-size: 3rem;
	letter-spacing: .1em;
}

/* !!------------------------------------ */
/* !! ナビゲーション */

#header{
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	height: 130px;
	z-index: 1000;
	background: #fff;
	transition: 200ms;
	body.header_small & {
		height: 80px;
	}
	.inner{
		height: 50px !important;
		margin: 0 50px;
		position: relative;
		top: 50%;
		@include transform(translateY(-50%));
		//background: #000;
	}
	
	nav#gnav{
		position: absolute;
		right: 0;
		top: 0;
		height: 50px;
		ul{
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			li{
				margin-left: 50px;
				a{
					display: block;
					line-height: 50px;
					position: relative;
					&:after{
						content: "";
						height: 2px;
						width: 100%;
						background: $txt;	
						display: block;
						@include transform(scaleX(0));
						transition: 300ms $ease1;
						opacity: 0;
						position: absolute;
						left: 0;bottom: 8px;
						width: 100%;
					}
					&:hover,&.current{
						&:after{
							opacity: 1;
							@include transform(scaleX(1));											
						}
					}
				}
			}
		}
	}//nav#gnav
}

#menu-btn{
	position: absolute;
	top: 15px;
	right: 10px;
	z-index: 3;
	cursor: pointer;
	pointer-events: auto;
	transition: 200ms;
	width: 50px;
	height: 50px;
	@include tap;
	overflow: visible;
	.inner{
		width: 25px;
		height: 25px;
		@include centering;
		overflow: visible;
	}
	i{
		display: block;
		height: 2px;
		width: 25px;
		background: #fff;
		position: absolute;
		transform-origin: right center;
		transition:500ms $ease1;

		&._1{
			top: 0;
			right: 0;
		}
		&._2{
			top: 9px;
			right: 0;
		}
		&._3{
			top: 18px;
			right:0;
		}
		body.menu_on &{
			//background: $txt;
		}
	}
	body.menu_on & {
		
		._1{
			right: 0;
			top: 9px;
			transform: rotate(-45deg) scaleX(.5);
		}
		._2{
			right: 0;
			transform:  scaleX(1);
		}
		._3{
			right: 0;
			top: 9px;
			transform: rotate(45deg) scaleX(.5);
		}
	}
}


/* !! - - -  */
/* !! gnav */


/* !!------------------------------------ */
/* !! トップページ */

/* !! - - -  */
/* !! MV */
body.top #MV{
	height: 100vh;
	padding-top: 130px;
	padding-left: 18%;
	.inner{
		position: relative;
		width: 100%;
		height: calc(100% - 230px);
		
		&.inv{
			h1{
				$d:polygon(0 0,0 100%,0 100%,0 0);
				@include clip-path($d);
				transition: 1300ms;
			}	
		}

		h1{
			$d:polygon(0 0,0 100%,100% 100%,100% 0);
			//$d:polygon(0 0,0 100%,0 100%,0 0);
			transition: 1300ms;
			@include clip-path($d);
			transition: 300ms;
			position: absolute;
			top: 50%;left: -125*$px;
			@include transform(translateY(-50%));
			line-height: 1;
			z-index: 2;
			span{
				display: block;
				@include OPS;
				&._1{
					font-size: 16px;
					letter-spacing: .05em;
				}
				&._2{
					margin: 15px 0 50px 0;
					font-size: 30px;
					letter-spacing: .05em;
				}
				&._3{
					font-size: 18px;
					letter-spacing: .1em;
				}
			}
		}
		.img{
			width:100%;height:100%;
			@include bg-img(url(../../img/mv@2x.jpg),cover);
			transition: 600ms;
			$d:polygon(0 0,0 100%,100% 100%,100% 0);
			//$d:polygon(0 0,0 100%,0 100%,0 0);
			@include clip-path($d);
		}
		&.trse{
			.img{
				$d:polygon(0 0,0 100%,100% 100%,100% 0);
				$d:polygon(0 0,0 100%,0 100%,0 0);
				@include clip-path($d);
			}
		}
	}
}

.btn-scrl{
	font-size: 12px;
	height: 70px;
	width: 100px;
	text-align: center;
	position: absolute;
	line-height: 1;
	bottom: 0px; left: 50%;
	@include transform(translateX(-50%));
	width: 200px;
	overflow: hidden;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	cursor: pointer;
	@include OPS;
	letter-spacing: .1em;
	&:before{
		display: block;
		content: "";
		width: 1px;
		height: 50px;
		background: #666;
		position: absolute;
		bottom: 0;left: 50%;
		z-index: 0;
	}
	&:after{
		display: block;
		transform-origin: left top !important; 
		content: "";
		width: 1px;
		height: 50px;
		background: #ccc;
		position: absolute;
		bottom: 0;left: 50%;
		z-index: 1;
		animation: scrollbar 2s ease -2s infinite;
		will-change:transform;
	}
}


@keyframes scrollbar {
	0% {
		@include transform(scaleY(0) translateY(0));
	}
	50% {
		@include transform(scaleY(1) translateY(0));
		
	}
	100%{
		@include transform(scaleY(1) translateY(100%));
	}
		
}



/* !! - - -  */
/* !! お知らせ */

%postlist{
	
	position: relative;
	letter-spacing: .1em;
	>a{
		@include a-cover;
		pointer-events: none;
	}
	
	.img{
		width: 230px;
		height: 230px;
		//background: #eee;
		&.no_thumb{
			background-color: #eee;
		}
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	
	.cate{
		background: #000;
		width: 100px;
		line-height: 24px;
		text-align: center;
		color: #fff;
		@include OPS;
		font-size: 14px;
		font-weight: bold;
	}
	.ttl{
		font-size: 2rem;
		line-height: 1.5;
		letter-spacing: 2px;
		margin: 5px 0;
	}
	.desc{
		font-size: 1.6rem;
	}
	
}//%postlis-txt


ul.post-list{
	position: relative;
	z-index: 1;
	overflow: hidden;
	
	li{	
		@extend %postlist;
		margin-bottom: 80px;
		float: right;
		width: 460px;
		.img{
			@include bg-img(url(../img/logo.png),80px auto); //$url,$size,$position,$repeat
		}
		&:first-of-type{
			margin-bottom: 80px !important;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.txt{
			width: 200px;
		}
		&:nth-of-type(8n + 1){
			position: relative;
			float: left !important;
			left: 0;
			top: 0;
			display: block;
			width: 460px;
			.img{
				width: 460px;
				height: 460px;
				margin-bottom: 30px;
			}
			.txt{
				width: 100%;
			}
		}
		
		&:nth-of-type(4n + 1){
			position: relative;
			float: right;
			left: 0;
			top: 0;
			display: block;
			width: 460px;
			.img{
				width: 460px;
				height: 460px;
				margin-bottom: 30px;
			}
			.txt{
				width: 100%;
			}
		}
		&:nth-of-type(4n + 2),
		&:nth-of-type(4n + 3){
			float: left;
		}
		&:nth-of-type(8n + 2),
		&:nth-of-type(8n + 3){
			float: right; 
		}
	}
	
	li.clr{
		width: 100%;
		display: block;
		height: 1px;
		clear: both;
		margin: 0;
	}
	
	li.notfound{
		text-align: center;
		margin: 0 auto !important;
		float: none !important;
		width: 500px;
		margin-bottom: 80px !important;
		
	}
	
}//ul.topics-list



section#topics{
	
	padding: 100px 0;
	padding-bottom: 0;
	.inner{
		position: relative;
		
		padding-top: 80px;
		padding-bottom: 50px;
		&:before{
			content:"";
			display: block;
			height: 100%;
			width: 100vw;
			position: absolute;
			top: 0;right: 67px;
			z-index: 0;
			background: $gray-lite2;
			@include transform(translateX(0%));
			transition: 600ms;
		}
		&.inv{
			&:before{
				content:"";
				@include transform(translateX(-100%));
			}
		}
	}	

	h2{
		position: absolute;
		left: 0;
		top: -25px;
	}
	
	
	
	a.btn{
		margin-left: auto;
		margin-right: 0;
		margin-top: 0px;
		z-index: 1;
		position: relative;
	}
	
}//section#topics





/* !! - - -  */
/* !! 私たちの特徴 */

section#feature{
	padding-top: 100px;
	.img{
		position: absolute;
		top: 0;right: -50px;
		z-index: 1;
		opacity: 1;
		@include transform(translateY(0%));
		transition: 600ms;
	}
	.txt{
		height: 400px;
		margin-top: 100px;
		width: 550px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		
		&:before{
			content:"";
			display: block;
			height: 100%;
			width: 100vw;
			position: absolute;
			top: 0;right: 0;
			z-index: 0;
			background: $gray-lite1;
			@include transform(translateX(0%));
			transition: 600ms;
			z-index: 0;
		}

		p{
			@include pfix(.87em);
			position: relative;
			z-index: 1;
			letter-spacing: .1em;
		}
		.btn{
			margin-top: 60px;
		}
		p,a{
			$d:polygon(0 0,0 100%,100% 100%,100% 0);
			@include clip-path($d);
			transition: 1000ms;
		}
	}
	.txt.inv{
		&:before{
			content:"";
			@include transform(translateX(-100%));
		}
	}
	.trse p,&.trse a{
		//$d:polygon(0 0,0 100%,100% 100%,100% 0);
		$d:polygon(0 0,0 100%,0 100%,0 0);
		@include clip-path($d);
	}
	.trse{
		.img{
			opacity: 0;
			@include transform(translateY(10%));
		}
	}
	
}//section#feature



/* !! - - -  */
/* !! 事業案内 */
section#business{
	padding-top: 100px;
	h2{
		text-align: center;
		margin-bottom: 50px;
	}
	
	.idx{
		>div{
			width: 50%;
			position: relative;
			.txt{
				
				h3{
					@extend h2.sec;
					.fit{
						letter-spacing: 0;
						text-align: justify;
						&:after{
							content: "";
							display: inline-block;
							height: 0px;
							width: 100%;
							background: #ccc;				
						}
					}
				}
				width: 480px;
				background: #fff;
				padding: 50px;
				padding-bottom: 0;
				margin: -80px 100px 0 auto;
				.btn{
					margin-left: auto;
					margin-right: 0;
				}
			}
			&._1 {
				.img{
					@include bg-img(url(../../img/img4@2x.jpg),cover); 
				}
			}
			&._2{
				.img{
					@include bg-img(url(../../img/img5@2x.jpg),cover); 
				}
				.txt{
					margin: -80px auto 0 100px;
				}
			}
		}
	}
	.img{
		height: 400px;
		background: #eee;
	}
	
	
}//section#busuness




/* !! - - -  */
/* !! 会社概要 */
section#company{
	position: relative;
	padding-top: 100px;
	.inner{
		max-width: 100%;
		margin: 0;
		height: 600px;
		width: 100% !important;
	}
	.wrap{
		position: relative;
		z-index: 1;
		max-width: 1000px;
		margin: 0 auto;
		letter-spacing: .1em;
		.txt{
			margin-top: 50px;
		}
		p{
			margin-bottom: 30px;
		}
	}
	#map{
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;left: 0;
		z-index: 0;
		$d:polygon(0 100% ,100% 100%,100% 0);
		
		@include clip-path($d);
		transition: 1000ms;
		&.inv{
			$d:polygon(100% 200% ,200% 200%,200% 0);
			@include clip-path($d);
		}
		html.ie &,html.edge &{
			&:before{
				content:"";
				position: absolute;
				top: 0;left: 0;
				width:100%;
				height:100%;
				z-index: 1;
				pointer-events: none;
				@include bg-img(url(../../img/mask2@3x.png)); //$url,$size,$position,$repeat
			}
		}
		iframe{
			width: 100%;
			height: 100%;
			-webkit-filter: grayscale(1);
			　filter: grayscale(1);
		}
	}
	.btn{
		position: absolute;
		bottom: -20px;
		left: 50%;
		@include transform(translateX(-50%));
		z-index: 1;
	}
}//section#company

/* !! - - -  */
/* !! 実績紹介 */
section#result{
	padding: 0;
	margin-top: 100px;
	background: $gray-lite1;
	position: relative;
	padding: 70px 0;
	h2{
		width: 1000px;
		position: absolute;
		z-index: 1;
		left: 50%;
		@include transform(translateX(-50%));
		top: -25px;
	}
	
	.lead{
		text-align: center;
		font-size: 1.6rem;
		letter-spacing: .1em;
		margin-bottom: 50px;
	}
	
	.btn{
		position: absolute;
		bottom: -20px;
		left: 50%;
		@include transform(translateX(-50%));
		z-index: 1;
	}
}//section#result

#slide,.related_post{
	.arr{
		width: 50px;
		height: 50px;
		background: #000;
		position: absolute;
		top: 200px;right: 0;
		z-index: 1;
		&:after{
			content: "";
			@include anchor(#fff,10px,2px,$right:36%);//$color,$size,$Bw,$left,$right					
		}
		&.prev{
			top: 200px;left: 0;
			right: auto;
			transform: rotate(180deg);
		}
	}
	white-space: nowrap;
	.cell{
		&:focus, *:focus{
			outline: none;
			border: none;
		}
		display: inline-block;
		@extend %postlist;
		width: 335px !important;
		white-space: normal;
		//padding:0 30px;
		margin: 0 40px;
		.img{
			width: 335px;
			height: 250px;
			margin-bottom: 30px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
		}
	}
	
	.detail{
		display: none !important;
		border-bottom: 2px dotted $txt;
		display: inline-block;
		font-weight: bold;
		@include OPS;
	}
	
}//#slide

/* !! - - -  */
/* !! 採用情報 */
section#recruit{
	margin-top: 100px;
	padding-top: 0px;
	height: 600px;
	position: relative;
	h2.sec{
		text-align: center;
		position: relative;
		z-index: 1;
		margin-top: 0;
	}
	.txt{
		margin-top: 80px;
		position: relative;
		z-index: 1;
		text-align: right;
		p{
			text-align: right;
			margin-top: 30px;
		}
		.btn{
			margin-left: auto;
			margin-right: 0;
			margin-top: 30px;
		}
	}
	.bg{
		z-index: 0;
		position: absolute;
		top: 0;left: 0;
		width:100%;
		height:100%;
		$d:polygon(0 0, 0 100%, 100% 100%);
		@include clip-path($d);
		transition: 500ms;
		&.inv{
			$d:polygon(-100% 0, -100% 100%, 0% 100%);
			@include clip-path($d);
		}
		@include bg-img(url(../../img/grade1@2x.jpg),cover); //$url,$size,$position,$repeat
		html.ie &,html.edge &{
			&:before{
				content:"";
				position: absolute;
				top: -1px;left: 0;
				width:100%;
				height:100%;
				z-index: 1;
				pointer-events: none;
				@include bg-img(url(../../img/mask1@3x.png)); //$url,$size,$position,$repeat
			}
		}
	}
}


/* !! - - -  */
/* !! お問い合わせ */
section#contact{
	padding: 0;
	background: #fff;
	.inner{
		padding: 100px 0;
		position: relative;
		max-width: 1000px;margin: 0 auto;

		>img{
			top: 50%;
			position: relative;
			display: inline-block;
			align-self: center;
		}
		.call{
			a.call{
				line-height: 60px;
				&:before{
					content:"";
					width: 25px;
					height: 25px;
					display: inline-block;
					margin-right: 5px;
					line-height: 60px;
					@include bg-img(url(../img/icon_tell@2x.png));
					
				}
				@include OPS;
				font-size: 3rem;
				letter-spacing: .1em;
			}
			.btn{
				height: 60px;
				line-height: 60px;
				width: 300px;
				margin-left: 30px;
			}
		}
	}
	
}//section#contact



/* !! - - -  */
/* !! フッター */
#footer{
	background: $gray-lite1;
	position: relative;
	a.pageTop{
		position: absolute;
		top: 330px;
		right: 50px;
		z-index: 2;
	}
	.inner{
		padding: 50px 0;
		max-width: 1000px;
		margin: 0 auto;
		position: relative;
		.logo{
			display: inline-block;
			vertical-align: top;
		}
		p.add{
			display: inline-block;
			vertical-align: top;
			white-space: nowrap;
			margin-left: 30px;
		}
		.sns{
			position: absolute;
			top:60px;right: 0;
			font-size: 0;
			a{
				display: inline-block;
				margin-left: 15px;
				@include tap;
				
			}
		}
		nav{
			display: block;
			margin-top: 30px;
			text-align: center;
			border-top: $line;
			padding-top: 20px;
			ul{
				font-size: 0;
				white-space: nowrap;
				li{
					display: inline-block;
					margin: 0px 15px;
					a{
						font-size: 14px;
						letter-spacing: .1em;
					}
				}
			}
		}
		
	}
	.inner2{
		width: 100%;
		margin: 0;
		background: #fff;
		height: 50px;
		padding: 0 50px;
		align-items: center;
		align-content: center;
		font-size: 12px !important;
		p{
			font-size: 12px;
		}
	}
}


/* !!------------------------------------ */
/* !! 下層ページ共通 */

#MV.page{
	height: 400px;
	width: 100%;
	margin: 0 auto;
	margin-top: 130px;
	.inner{
		position: relative;
		width: 1000px;
		margin: 0 auto;
		height: calc(100%);
		h1{
			width: 1000px;
			max-width: 1000px;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -50px;
			@include transform(translate(0,-50%));
			line-height: 1;
			z-index: 1;
		}
		.img{
			width:calc(100vw - 100px - ((100vw - 1000px) / 2));
			height:100%;
			left: 100px;
			z-index: 0;
			position: relative;
		}
	}
}

section.lead{
	padding: 0px;
	//background: #000;
	position: relative;
	margin-top: -100px;
	
	.inner{
		padding: 50px;
		padding-left: 100px;
		margin: 0 auto;
		
		&:before{
			content:"";
			width: 100vw;
			background: #000;
			height: 100%;
			position: absolute;
			top: 0;right: 0;
			z-index: 0;
		}
	}
	.txt{
		position: relative;
		z-index: 1;
		color: #fff;
		letter-spacing: .1em;
	}
	
	.img{
		top: 0;right: 0;
		position: absolute;
		z-index: 2;
	}
}


main.sim > .inner{
	padding-top: 180px;
	width: 750px;
	margin: 0 auto;	
}

/* !!------------------------------------ */
/* !! 私たちの特徴 */

main#feature{
	
#MV .img{
	@include bg-img(url(../../feature/img/mv@2x.jpg),cover);
	
}

.lead{
	._2{
		background: #fff;
		&:before{
			background: #fff;
		}
		p{
			color: $txt !important;
		}
	}
}

.shop-brand{
	h2{
		text-align: center;
	}
	.idx{
		max-width: 1000px;
		margin: 70px auto;
		justify-content: space-between;
		
		.inner{
			position: relative;
			height: 300px;
			h3{
				position: absolute;
				top: 0;left: 50%;
				background: #FFF;
				padding: 0 20px;
				@include transform(translate(-50%,-50%));
				img{
					margin: 0;
				}
			}
			border: $line;
			width: calc(50% - 20px);
			justify-content: space-around;
			
		}
		img{
			display: block;
			height: auto !important;
			margin: 0 0px;
			align-self: center;
		}
	}
}


.message{
	max-width: 1100px;
	padding: 50px 0;
	margin: 0 auto;
	background: $gray-lite2;
	p{
		text-align: center;
		font-size: 16px;
		color: $gray;
	}
}
	
}//main#feature




/* !!------------------------------------ */
/* !! 会社概要 */

main#company{
	
#MV .img{
	@include bg-img(url(../../company/img/mv@2x.jpg),cover);
}

.lead{
	._1{
		align-items: center;
		
		p{
			float: right;
			position: relative;
			z-index: 1;
			color: #fff;
			text-align: right;
		}
	}
	._2{
		&:before{background: #3E3A39;}
	}
	._3{
		&:before{background: #fff;}
		p{
			color: $gray;
			margin-bottom: 1em;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		img{
			display: none;
			position: absolute;
			bottom: 0;
			right: -200px;
			z-index: 1;
			transition: 500ms $ease1;
			backface-visibility: hidden;
			@include transform(scale(1) translateX(0));
			opacity: 1;
			transform-origin: right bottom;
			&.inv{
				opacity: 0;
				@include transform(scale(1) translateX(50%));
			}
		}
	}
}


//!! - outline

.outline{
	
	padding: 100px 0 160px;
	background: $gray-lite1;
	h2{
		text-align: center;
	}
	
	div.idx{
		margin-top: 50px;
		>ul{
			width: calc(50% - 40px);
			border-top: 1px dotted $txt;
			>li{
				position: relative;
				padding: 15px 0;
				padding-left: 110px;
				font-size: 14px;
				line-height: 2;
				letter-spacing: .1em;
				border-bottom: 1px dotted $txt;
				>i{
					position: absolute;
					top:20px;left: 0;
				}
			}
			&._1{
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				>li{
					line-height: 1.7;
					padding: 15px 0;
					padding-top: 10px;
					padding-left: 110px;
					>i{
						top:10px;
					}
				}
			}
		}
	}
	
	
	
}//.outline
	

//!! - map
.map{
	height: 500px;
	max-width: 1000px;
	margin: 0 auto;	
	margin-top: -85px;
	position: relative;
	iframe{
		width: 100%;
		height: 100%;
		-webkit-filter: grayscale(1);
		filter: grayscale(1);
	}
	.btn{
		position: absolute;
		left: 50%;bottom: 0;
		@include transform(translate(-50%,50%));
	}
}



}//main#company


//!! - 沿革
section.history{
	padding: 100px 0;
	h2{text-align: center;}
	.btn-toggle{
		margin: 0 auto;
		margin-top: 50px;
	}
	ul.timeline{
		margin: 0 auto;
		margin-top: 50px;
		width: 800px;
		display: none;
		>li{
			padding-left: 170px;
			position: relative;
			min-height: 100px;
			display: flex;
			align-content: center;
			//align-items: center;
			flex-direction: column;
			justify-content: center;
			margin-bottom: 60px;
			&:last-of-type{
				margin-bottom: 0;
				&:after{
					content: none;
										
				}
			}
			&.top{
				justify-content:flex-start;
			}

			ul{
				li{
					position: relative;
					line-height: 2;
					i{
						position: absolute;
						left: -20px;
						top: 0;
						@include transform(translateX(-100%));
					}
				}
			}
			&:after{
				content: "";
				display: block;
				position: absolute;
				left: 0;bottom: -30px;
				width: 100% !important;
				height: 1px;
				border-bottom: 1px dotted $txt;					
			}
			>i{
				position: absolute;
				left: 0;
				width: 100px;height: 100px;
				border-radius: 50%;
				font-size: 2rem;
				background: $gray;
				display: block;
				color:#fff;
				font-weight: bold;
				@include OPS;
				display: flex;
				letter-spacing: .1em;
				//align-content: center;
				align-items: center;
				justify-content: center;
			}
		}
	}
	
}//section.history


//!! - pertner
section.partner{
	background: $gray-lite1;
	padding: 0;
	padding-bottom: 70px;
	h2{
		text-align: center;
		position: relative;
		&._1{
			top: -25px;
		}
	}
	
	ul{
		max-width: 1100px;
		margin: 0 auto;
		justify-content: center;
		li{
			margin: 25px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			img{
				display: block;
				mix-blend-mode: multiply;
			}
		}
	}
	
	h2._2{
		margin-top: 50px;
		margin-bottom: 30px;
	}
	
}//section.partner



/* !!------------------------------------ */
/* !! 事業案内 */

main#business{
overflow: hidden;
#MV .img{
	@include bg-img(url(../../business/img/mv1@2x.jpg),cover);
}
	
.lead{
	&._2{
		margin-top: 170px;
	}
	padding: 0;
	.inner{
		padding: 0;
		max-width: 1000px;
		position: relative;
		&:before{
			content:"";
			display: none;
		}
		.img{
			text-align: right;
			font-size: 0;
		}
		.txt{
			right: 0;
			left: 0;
			h2{
				text-align: left;
			}
			&:before{
				content:"";
				left: auto;
				right: 0;
			}
		}
	}
	.txt{
		position: absolute;
		top: 50%;
		right: 0;
		@include transform(translateY(-50%));
		width: 500px;
		height: 160px;
		z-index: 3;
		text-align: right;
		display: flex;
		flex-direction: column;
		justify-content: center;
		h2{
			text-align: right;
			position: relative;
			z-index: 1;
		}
		&:before{
			content:"";
			position: absolute;
			top: 0;left: 0;
			z-index: 0;
			width:100%;
			height:100%;
			background: #000;
			width: 100vw;
		}

	}
	.img{
		position: relative;
	}
}


//!! - div1-field
.div1-field{
	padding-top: 100px;
	.field{
		width: calc(50% - 40px);
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		&.col2{
			width: 100%;
			margin-bottom: 80px;
			.img{
				height: auto;
			}
			p{
				display: block;
				width: 100%;
				letter-spacing: .1em;
				margin: 0;
			}
		}
		.img{
			position: relative;
			align-self: flex-start;
			height: 60px;
			@include flex-centering;
			width: 100%;
			margin-bottom: 50px;
		}
		p{
			align-self: flex-start;
			display: flex;
			font-size: 14px;
			line-height: 2;
			margin-bottom: 50px;
		}
		
		.link{
			.cover{
				@include a-cover;
			}
			align-self: flex-end;
			margin-top: auto;
			width: 100%;
			border: 1px solid $txt;
			height: 100px;
			justify-content: space-between;
			padding-left: 50px;
			align-items: center;
			position: relative;
			&:before{
				content:"";
				width: 70px;
				height: 1px;
				background: $txt;
				display: block;
				position: absolute;
				top: 50%;left: -45px;
			}
			img{
				height: 43px !important;
				width: auto !important;
			}
			a{
				
			}
		}
		
	}//.field
	
}//.div1-field


.field-2{
	border-top: $line;
	border-width: 1px 0;
	max-width: 1000px;
	margin: 80px auto;
	margin-bottom: 0;
	padding: 80px 0;
	h3{
		margin-bottom: 30px;
	}
	>div{
		max-width: 280px;
		p{
			font-size: 14px;
			line-height: 2;
		}
	}
	
}//.field-2


//!! - 沿革
.history.div1{
	padding-top: 0;
	max-width: 1000px;
	padding-bottom: 0;
	margin: 0 auto;
	border-top: $line;
	border-bottom: $line;
	position: relative;
	>ul{
		display: none;
		margin-bottom: 100px;
	}
	.btn-toggle{
		margin-top: 0;
		//@include transform(translateY(-50%));
		position: absolute;
		left: 50%;
		bottom: 0;
		@include transform(translate(-50%,50%));
		&:after{
			content:"企画販売室の歩み";
		}
	}
}

//!! - 店舗情報

.shop-info{
	
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 80px;
	>div{
		width: calc(50% - 40px);
		@include flex-centering;
		justify-content: flex-start;
		.img{
			width: 100%;
			height: 345px;
		}
		.ttl{
			position: relative;
			z-index: 1;
			width: 360px;
			background: #fff;
			height: 130px;
			margin: 0 auto;
			margin-top: -50px;
			@include flex-centering;
		}
		ul{
			align-self: stretch;
			flex-grow: 2;
			margin-left: 140px;
			padding-left: 25px;
			border-left: 2px dotted #EA5504;
			font-size: 14px;
			li{
				padding: 10px 0;
				position: relative;
				i{
					position: absolute;
					top: 10px;
					left: -50px;
					@include transform(translateX(-100%));
					text-align: right;
					width: 100px;
				}
			}
		}
	}
	
}//.shop-info


// - field2
.field3{
	padding-top: 80px;
	.mv{
		height: 400px;
		width: 100%;
		@include bg-img(url(../../business/img/img4@2x.jpg),cover); //$url,$size,$position,$repeat
	}
	
	.field-grp3{
		max-width: 1000px;
		margin: 0 auto;
		padding: 50px;
		margin-top: -150px;
		background: #fff;
	}
	
	.box{
		margin-bottom: 80px;
		width: calc(50% - 40px);
		&._1{
			width: 100%;
		}
		&._4{
			width: 100%;
			padding-left: 500px;
			height: 400px;
			position: relative;
			@include flex-centering;
			align-items: flex-start;
			h3{
				i{
					white-space: nowrap;
					letter-spacing: 0em;
				}
			}
			.img{
				position: absolute;
				top: 0;
				right: 460px;
				height: 400px;
				background: #ccc;
				width: 50vw;
				min-width: 500px;
				@include bg-img(url(../../business/img/img5@2x.jpg),cover); //$url,$size,$position,$repeat
			}
		}
		h3{
			margin-bottom: 30px;
			i{
				font-weight: 700;
			}
		}
		p{
			font-size: 14px;
			@include pfix(.1em);
		}
	}
	
}//.field2
	
}//main.business

/* !!------------------------------------ */
/* !! iPhone修理 */

main#repair{
	
	#MV .img{
		@include bg-img(url(/repair/img/mv@2x.jpg),cover);
	}
	
	.inner._2{
		&:before{
			content:none;
		}
		.txt{
			color: $txt;
			span{
				font-size: 14px;
			}
		}
		.call{
			line-height: 1.5;
		}
		ul{
			width: 445px;
			padding: 20px;
			margin-top: 20px;
			border: 1px solid $txt;
			li{
				position: relative;
				padding-left: 1em;
				min-width: 50%;
				font-size: 14px;
				letter-spacing: 0;
				&:before{
					content:"●";
					font-size: 10px;
					line-height: 22px;
					position: absolute;
					top: 0;left: 0;
					@include transform(scale(.8));
					display: inline-block;
				}
			}
		}
	}
	
	
	.map{
		width: 1000px;
		height: 300px;
		margin: 50px auto;
		background: #eee;
		position: relative;
		iframe{
			width:100%;
			height:100%;
			-webkit-filter: grayscale(1);
			filter: grayscale(1);
		}
		.add{
			height: 100px;
			background: #000;
			padding:0 50px;
			white-space: nowrap;
			color: #fff;
			position: absolute;
			top: 50%;
			right: -100px;
			@include flex-centering;
			@include transform(translateY(-50%));
		}
	}
	
	
	//!! - 割引
	#discount{
		.idx{
			margin-bottom: 50px;
			&:before{
				content:"";
				height: 100%;
				width: 1px;
				border-left: 2px dotted $txt;
				position: absolute;
				left: 50%;
			}
			>div{
				width: calc(50% - 40px);
			}
			h3{
				font-size: 2.6rem;
				line-height: 1;
				letter-spacing: .1em;
				margin-bottom: 10px;
			}
			p{
				font-size: 14px;
				line-height: 1.6;
				letter-spacing: .1em;
				@include pfix(.6em);
			}
			
			ul{
				margin-top: 20px;
				li{
					position: relative;
					padding-left: 135px;
					margin-bottom: 12px;
					line-height: 2;
					i{
						position: absolute;
						top: 0;
						left: 0;
						width: 120px;
						height: 24px;
						line-height: 22px;
						text-align: center;
						border: 1px solid $txt;
					}
					a{
						display: inline-block;
						border-bottom: 1px dashed $txt;
					}
				}
				.nw{
					white-space: nowrap;
				}
			}
		}
		
		.btn-modal{
			margin: 0 auto;
			margin-top: 30px;
		}
	}
	
}//main#repair



/* !!------------------------------------ */
/* !! モーダル */
#modal-shade{
	position: fixed;
	width: 120%;
	height: 120%;
	background: rgba(0, 0, 0, 0.8);
	top: -10%;left: 0;
	z-index: 1001;
	pointer-events: none;
	opacity: 0;
	transition: 300ms;
	body.modal_on & {
		opacity: 1;
		pointer-events: auto;
	}

}

#modal-window{
	position: fixed;
	top: 50%;left: 50%;
	@include transform(translate(-50%,-40%));
	width: 80vw;
	height: 80vh;
	z-index: 1002;
	opacity: 0;
	pointer-events: none;
	transition: 300ms ease-in-out;
	img{
		display: block;
		height: 80vh !important;
		width: auto !important;
		pointer-events: none;
		margin: 0 auto;
	}
	body.modal_on & {
		opacity: 1;
		pointer-events: auto;
		@include transform(translate(-50%,-50%));
	}
}

/* !!------------------------------------ */
/* !! 採用情報 */
main#recruit{
	
#MV .img{
	@include bg-img(url(../../recruit/img/mv@2x.jpg),cover); //$url,$size,$position,$repeat
}

.lead {
	._2:before{
		background: #231b18;
	}
	._3{
		.txt{
			color: $txt;
		}
		&:before{
			content:"";
			background: #fff;
		}
		p{
			margin-bottom: 1em;
			&:last-of-type{	
				margin-bottom: 0;
			}
		}
	}
}

//!! - 募集要項
#requirements{
	padding: 100px 0;
	background: $gray-lite2;
	h2{
		text-align: center;
		i{
			margin-bottom: 0;
			margin-top: 10px;
		}
		& + .idx{
			justify-content: center !important;
			margin: 50px 0 80px 0;
			a{
				margin: 0 20px;
			}
		}
	}
	
	h3{
		letter-spacing: .1em;
		font-weight: normal;
		font-size: 18px;
		padding-bottom: 15px;
		border-bottom: 1px solid $txt;
		margin-top: 80px;
		&:first-of-type{
			margin-top: 0;
		}
	}
	
	.btn{
		margin: 0 auto;
	}
	
	ul.requirements{
		margin: 50px 0;
		>li{
			position: relative;
			padding-bottom: 15px;
			border-bottom: 1px dotted $txt;
			padding-left: 110px;
			margin-bottom: 30px;
			p{
				margin-bottom: .6em;
				&:last-of-type{margin-bottom: 0;}
			}
			>i{
				position: absolute;
				top: 0;left: 0;
			}
			ul{
				line-height:1.7;
				list-style: disc;
				padding-left: 1em;
			}
			span{
				display: inline-block;
				width: 90px;
			}
		}
	}
}

}//main#recruit


/* !!------------------------------------ */
/* !! プライバシポリシー */

main#privacypolicy{
	
	>.inner{
		padding-top: 180px;
		width: 750px;
		margin: 0 auto;
	}	
	.lead{
		margin: 50px 0;
		text-align: justify;
		&:after{
			content: "";
			display: inline-block;
			width: 100%;
			height: 0;					
		}
	}
	
	section.content{
		padding: 80px 0;
		display: block;
		position: relative;
		.inner{
			width: 100%;
			position: relative;
			z-index: 1;
		}
		&:before{
			content:"";
			width: 100vw;
			height: 100%;
			top: 0;right: -60px;
			z-index: 0;
			position: absolute;
			background: $gray-lite2;
		}
		
		dt{
			margin-top: 50px;
			&:first-of-type{
				margin-top: 0;
			}
			font-size: 18px;
			border-bottom: 1px solid $txt;
			line-height: 1;
			padding-bottom: 15px;
			margin-bottom: 20px;
			font-weight: bold;
			letter-spacing: .1em;
		}
		dd{
			font-size: 14px;
			line-height: 2;
			ul{
				list-style: disc;
				padding-left: 1.6em;
			}
		}
	}
	
}//main#privacypolixy

/* !!------------------------------------ */
/* !! お問い合わせ */

main#contact{
		
	.lead{
		text-align: center;
		line-height: 1;
		margin: 50px 0;
		font-weight: bold;
		letter-spacing: .1em;
	}
	
	p.hd{
		text-align: center;
		font-size: 1.6rem;
		font-weight: bold;
		letter-spacing: .1em;
		line-height: 1;
		@include transform(translateY(50%));
		position: relative;z-index: 1;
		
	}
	
	section.content{
		padding: 0px;
		padding-top: 60px;
		padding-bottom: 80px;
		display: block;
		position: relative;
		.inner{
			width: 100%;
			position: relative;
			z-index: 1;
		}
		&:before{
			content:"";
			width: 100vw;
			height: 100%;
			top: 0;right: -60px;
			z-index: 0;
			position: absolute;
			background: $gray-lite2;
		}
	}
	
		#mailformpro{
			margin: 0 auto;
			position: relative;
			background: #FFF !important;
			padding: 0;
			padding-top: 0;
		}
		
		
		.idx{
			>div{
				width: calc(50% - 15px);
				margin-bottom: 30px;
				p{
					line-height: 1;
					font-size: 1.4rem;
					margin-bottom: 10px;
					letter-spacing: .1em;
					span{
						color: rgba(255, 16, 16, 1);
						background: rgba(255, 16, 16, .6);
						color: #fff;
						font-size: 12px;
						line-height: 16px;
						margin-left: 5px;
						padding: 0 5px;
						display: inline-block;
					}
				}
				&.txtarea{
					width: 100% !important;
					textarea{
						height: 200px;
						width: 100%;
						display: block;
					}
				}
			}
		}
		//!! - 住所
		
		div.add{
			width: 100%;
			div.zip-wrap{
				width: 250px !important;
				position: relative;
				&:before{
					content:"〒";
					vertical-align: bottom;
					line-height: 40px;
					width: 40px;
					text-align: center;
					position: absolute;
					top: 1px;left: 0;
				}
			}
			#zip{
				width: 100%;
				padding-left: 40px;
			}
			.add{
				width: 470px !important;
			}
		}
		
		input[type="text"],
		input[type="email"],
		input[type="tel"],label{
			display: block;
			padding: 0;
			border: none;
			max-width: 100%;
			padding: 0 8px;
			font-size: 14px;
			height: 40px;
			width: 100% !important;
			border-radius: 0 !important;
			box-shadow: none !important;
			margin: 0;
			&:focus{
				background: #ebfbff !important;
				outline: none;
			}
		}
		

		label{
			height: 80px;
			display: block;
			padding: 0 30px;
			width: calc(50% - 15px) !important;
			margin: 0 !important;
			margin-bottom: 30px !important;
			border: 1px solid $txt;
			
			span{
				line-height: 1;
				display: block;
				font-size: 18px;
				top: 50%;position: relative;
				@include transform(translateY(-50%));
				padding: 0 03px;
				padding-left: 40px;
				&.checked:after{
					opacity: 1;
				}
				&:after{
					opacity: 0;
					content: "";
					display: block;
					width: 20px;
					border: 1px solid $txt;
					border-width: 0 0 1px 1px;
					height: 10px;
					position: absolute;
					top: -3px;left: 4px;
					
					@include transform(rotate(-45deg));					
				}
				input{
					appearance: none;
					display: block;
					width: 20px;
					height: 20px;
					border: 1px solid $txt;
					position: absolute;
					top: 0;left: 0;
				}
			}
		}
		
		.select{
			position: relative;
			padding-left: 166px;
			margin: 30px 0;
			.hide{
				display: none !important;
			}
			span{
				color: rgba(255, 13, 13, 1);
			}
			i{
				line-height: 40px;
				font-size: 18px;
				position: absolute;
				top: 0;left: 0;
			}
			.wrap{				
				height: 40px;
				&:after{
					content: "";
					@include anchor();//$color,$size,$Bw,$left,$right
					border-width: 0 0 1px 1px;
					margin-top: -3px;
									
				}
				select{
					font-size: 15px;
					appearance: none;
					width: 100%;
					height: 100%;
					display: block;
					max-width: 1000px;
					background: none;
					box-shadow: none;
					line-height: 30px;
					border: 1px solid $txt;
					margin: 0;
					border-radius: 0;
					&:focus{
						outline: none;
					}
				}
			}
		}
		
		textarea{
			padding:10px;
			border: none;
			max-width: 100%;
			height: 100px !important;
			width: 100% !important;
			font-size: 14px;
			border-radius: 0 !important;
			box-shadow: none !important;
			&:focus{
				background: #ebfbff !important;
				outline: none;
			}
		}
		
		
		
		.mfp_err{
			background: none;
			padding-left: 0;
			font-size: 12px;
			letter-spacing: .1em;
		}
		
		.note{
			text-align: center;
			line-height: 1;
			margin-bottom: 30px;
		}
		
		.btn-wrap{
			text-align: center;
		}
		
		.mention{
			border: 1px solid $txt;
			padding: 30px;
			font-size: 14px;
			a{
				color: $blue;
				text-decoration: underline;
				font-weight: bold;
			}
			b{
				font-size: 1.14em;font-weight: normal;
				display: block;
				margin-bottom: 10px;
			}
		}
		
		.idx.sim{
			justify-content: center;
			margin: 50px 0;
			label{
				padding: 0;
				margin: 0 !important;
				height: auto;
				border: none;
				width: auto;
			}
		}
		
		.btn-wrap input,
		.mfp_element_button{
			border-radius: 0;
			text-shadow: none;
			appearance: none;
			display: inline-block;
			margin: 0 15px;
			background: $base;
			color: #fff;
			width: 300px;
			line-height: 60px;
			font-weight: bold;
			border: none;
			font-size: 14px;
			letter-spacing: .1em;
			&#mfp_button_cancel{
				background: $gray2;
			}
			&[type=reset]{
				background: $gray2;
			}
		}
		
	
	#mfp_phase_confirm{
		max-width: 1000px;
		margin:0 auto;
		margin-top: 50px;
		padding-top: 0;
		background: #fff;
		position: relative;
		//top: -100px;

	}
	#mfp_phase_confirm_inner{
		//overflow: hidden;
		position: relative;
		z-index: 1;
		padding: 80px 0;
		padding-top: 0;
		margin-top: 0px;
		&:empty{
			border: none;
			display: none;
		}
		&:before{
			content:"";
			width: 100vw;
			height: 100%;
			top: 0;right: -60px;
			z-index: 0;
			position: absolute;
			background: $gray-lite2;
		}
		h4{
			font-size: 16px;
			line-height: 1;
			max-width: 1000px;
			padding-top: 0;
			@include transform(translateY(-50%));
			margin: 0 auto;
			letter-spacing: .1em;
			margin-bottom: 50px !important;
		}
		
		#mfp_confirm_table{
			position: relative;
			z-index: 1;
			border: 1px dashed #aaa;
			margin-bottom: 50px;
			border-collapse: collapse;
			width: 100%;
			td,th{
				font-size: 14px;
				padding: 15px;
				font-weight: normal;
				background: #FFF !important;
				border-style: dashed;
				border: 1px dashed #aaa;
			}
		}
		.mfp_buttons{
			padding: 0;
			position: relative;
			z-index: 1;
		}
	}
	
	#mfp_warning{
		@include transform(translateY(-50%));
		z-index: 2;
		background: none;
		font-size: 12px;
		border: none;
		color: $red;
		position: relative;
		top: 0;
		text-align: center;
		left:auto;
		margin: 0 auto !important;
		height: 50px;
		top: -50px;
		max-width: 800px;
	}
	
	
	//!! - thanks
	&.thanks{
		text-align: center;
		height:calc(100vh - 540px );
		min-height: 500px;
		position: relative;
		p.accept{
			border: 1px dashed #aaa;
			
			@include centering;
			display: inline-block;
			margin-top: 24px;
			padding: 50px;
			line-height: 1.8;
			white-space: nowrap;
			b{
				display: block;
				font-size: 1.2em;
				letter-spacing: .1em;
			}
		}
	}
	
}//main#contact



/* !!------------------------------------ */
/* !! お知らせ　TOPICS & 事例紹介 */
main#topics-index > .inner,
main#result-index > .inner{
	max-width: 1000px !important;
	width: 100%;
	>section{
		padding-top: 0 !important;
	}
	.inner{
		position: relative;
		padding-top: 80px;
		padding-bottom: 0px;
		&.before-only{
			&:after{
				content: none;					
			}
		}
		&:before{
			content:"";
			display: block;
			height: 100%;
			max-height: 800px;
			width: 100vw;
			position: absolute;
			top: 0;right: 67px;
			z-index: 0;
			background: $gray-lite2;
		}
		&:after{
			content:"";
			display: block;
			height: 600px;
			width: 100vw;
			position: absolute;
			bottom: 0;left: 67px;
			z-index: 0;
			background: $gray-lite2;
		}
	}	
	.post-list{
		padding-bottom: 0;
		position: relative;
	}
	
}

/* !! カテゴリ */
.cate-list{
	margin: 50px 0;
	display: flex;
	justify-content: center;
	@include OPS;
	li{
		&:last-of-type{
			a{
				border-right: 0;
			}
		}
		a{
			display: block;
			width: auto;
			font-size: 14px;
			letter-spacing: .1em;
			font-weight: bold;
			padding: 2px 20px;
			line-height: 1;
			border-right: 1px solid $txt;
		}
	}
}

/* !! ページャー */
.wp-pagenavi{
	margin: 50px auto;
	text-align: center;
	padding: 0 30*$px;
	position: relative;
	width: 1000px;
	@include OPS;
	font-size: 2rem;
	font-weight: bold;
	a,span{
		width: 20px;
		height: 36px;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		line-height: 36px;
		margin: 0 10px;
		&.current{
			//color: #fff;
			border-bottom: 2px solid $txt;
		}
	}
	.pages{
		display: none;
	}
	.previouspostslink{
		position: absolute;
		top: 0;left: 0;
		font-size: 0;
		width: 36px;height: 36px;
		background: none;
		float: left;
		display: inline-block;
		@include bg-img(url(/img/top/slide-anchor@3x.png),10px 23px,left center); //$url,$size,$position,$repeat
	}
	.nextpostslink{
		position: absolute;
		top: 0;right: 0;
		font-size: 0;
		float: right;
		background: none;
		width: 36px;height: 36px;
		display: inline-block;
		@include bg-img(url(/img/top/slide-anchor@3x.png),10px 23px,left center); //$url,$size,$position,$repeat
		transform: rotate(180deg);
	}
	.last,.first{
		display: none;
	}
}


/* !! - - -  */
/* !! single */

main.single{
	>.inner{
		width: 800px;
	}
	#article-header{
		background: #eee;
		height: 350px;
		width: 1000px;
		position: relative;
		h1{
			line-height: 1.2;
			background: -webkit-linear-gradient(45deg,#ffffff 0%, rgba(255,255,255,0.03) 80%);
			width: 100%;
			position: absolute;
			left: 0;
			top: 50%;
			@include transform(translateY(-50%));
			z-index: 1;
			font-weight: 600;
			i{
				padding: 20px 0 20px 45px;
				display: block;
				font-size: 2.4rem;
				line-height: 1.5;
				letter-spacing: .1em;
				@include pfix(.5em);
			}
		}
		i.cate{
			position: absolute;
			top: 50%;
			left: 0;
			transform-origin: center center;
			@include transform(translate(-50%,-50%) rotate(90deg));
			z-index: 1;
			height: 30px;
			width: 100px;
			text-align: center;
			line-height: 30px;
			background: #000;
			color: #fff;
			font-size: 14px;
			letter-spacing: .1em;
			@include OPS;
			font-weight: 600;
			&:after{
				content: "";
				position: absolute;
				bottom: 0;
				right: -10px;
				width: 10px;
				height: 15px;
				@include bg-img(url(../../topics/img/anchor@2x.png)); //$url,$size,$position,$repeat				
			}
		}
		.img{
			overflow: hidden;
			width:100%;
			height:100%;
			position: relative;
			img{
				filter: blur(4px);
				display: block;
				width: 105% !important;
				height: auto !important;
				@include centering;
			}	
		}
	}
	
	article{
		p{
			font-size: 1.6rem;
			line-height: 1.88;
			padding:0 50px;
			margin-bottom: 50px;
			&:last-of-type{

			}
		}
		.desc{
			padding:0 50px;margin-top: 50px;
		}
		.cap{
			font-size: 1.4rem;line-height: 1.88;
		}
		
		h2{
			text-align: center;
			margin-top: 80px;
			font-weight: 600;
			letter-spacing: .1em;
			&:after{
				content: "";
				width: 50px;
				height: 2px;
				background: $txt;	
				display: block;
				margin: 50px auto;
				margin-top: 15px;			
			}
		}
		
	}


	.shop-info{
		border-top: 1px solid $txt;
		border-width: 1px 0;
		padding: 50px 0;
		padding-bottom: 100px;
		position: relative;
		.inner{
			position: relative;
			padding-left: 280px;
			@include flex-centering;
			height: 250px;
			align-items: flex-start;
		}
		.img{
			position: absolute;
			top: 0;left: 0;
			border-radius: 50%;
			overflow: hidden;
			width: 250px;
			height: 250px;
			img{
				height: 100%;
				width: auto !important;
				position: relative;
				left: 50%;
				@include transform(translateX(-50%));
			}
		}
		p{
			font-size: 1.8rem;
			font-weight: 600;
			letter-spacing: .1em;
			line-height: 1;
			margin-bottom: 20px;
		}
		ul{
			margin-top: 0px;
			font-size: 16px;
			li{
				padding-left: 90px;
				position: relative;
				margin-top: 15px;
			}
			i{
				position: absolute;
				left: 0;top: 0;
			}
		}
	}
	
	.sns{
		position: relative;
		text-align: center;
		font-size: 0;
		line-height: 1;
		&:after{
			content: "";
			width: 100%;
			display: block;
			height: 1px;
			position: relative;
			bottom: 15px;
			z-index: 0;
			background: $txt;
		}
		p{
			font-size: 12px;
			margin-bottom: 20px;
			line-height: 1;
			text-align: center;
		}
		a{
			margin: 0 7px;
			display: inline-block;
			position: relative;
			z-index: 1;
		}
	}
	
	.btn.back{
		margin: 100px auto;
	}
	
	
	//!! - 関連記事
	#relates-articles{
		padding: 0;
		background: $gray-lite2;
		width: 1000px;
		margin-left: -100px;
		position: relative;
		h2.sec{
			position: absolute;
			top: -23px;
			left: 100px;
			i{
				margin-top: 10px;
				margin-bottom: 0;
			}
		}
		h3{
			display: none;
		}
		ul.related_post{
			white-space: nowrap;
			padding: 0 80px;
			.slick-list{
					
			}
			*:focus{
				outline: none;
			}
			li{
				display: inline-block;
				width: 233px;
				height: 233px;
				margin: 80px 25px;
				position: relative;
				.wp_rp_thumbnail{
					display: block;
					img{
						width: 100% !important;
						height: auto !important;
					}
				}
				.wp_rp_title{
					background: #fff;
					position: absolute;
					z-index: 1;
					bottom: -30px;right: -20px;
					width: 215px;
					padding: 10px;
					padding: 0 20px;
					white-space: normal;
					font-size: 16px;
					font-weight: 600;
					height: 80px;
					@include flex-centering;
					align-content: flex-start;
				}
				.wp_rp_category{
					position: absolute;
					top:0;
					left: 0;
					transform-origin: left top;
					@include transform( rotate(90deg) translate(0%,-50%));
					z-index: 1;
					height: 24px;
					width: 100px;
					text-align: center;
					line-height: 24px;
					background: #000;
					color: #fff;
					font-size: 0;
					
					@include OPS;
					font-weight: 600;
					a{
						font-size: 14px;
						letter-spacing: .1em;
					}
					&:after{
						content: "";
						position: absolute;
						bottom: 0;
						right: -8px;
						width: 8px;
						height: 12px;
						@include bg-img(url(/common/img/anchor@2x.png)); //$url,$size,$position,$repeat				
					}
				}
			}
			.arr{
				top: 50%;right: -25px;
				z-index: 1;
				@include transform(translateY(-50%));
				&:after{
					content: "";
					@include anchor(#fff,10px,2px,$right:36%);//$color,$size,$Bw,$left,$right					
				}
				&.prev{
					top: 50%;left: -25px;
					right: auto;
					transform:translateY(-50%) rotate(180deg);
				}
			}
		}
		.wp_rp_footer{
			display: none;
		}
		
	}
	
}//main.single


